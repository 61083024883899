import { Injectable } from "@angular/core";
import {
  BinMasterDto,
  CycleCountDto,
  DocumentLogDto,
  ErrorLogDto,
  ListResultDto,
  LookupObjectDto,
  SearchDto,
  StockBinDto,
  StockTakeBinDto,
  StockTakeDto,
  StockWarehouseDto,
  WarehouseMasterDto,
  newSearchDto,
} from "@shared/models";
import { LoadDto } from "@modules/warehouse/load-planning/models/LoadDto";
import { DeliveryBookingDto } from "@modules/warehouse/load-planning/models/DeliveryBookingDto";
import { DataService, ServiceConfig, sameDate } from "..";
import { ListDataService } from "./list-data.service";

export const lookupFuncCategoryDisplay = (cat) =>
  cat ? `${cat.code} ~ ${cat.description}` : "";

/**
 *    @p_level             int         = 1
, @p_col1value         varchar(64) = NULL -- update can be customer
, @p_col2value         varchar(64) = NULL -- update can be address
, @p_warehouse         varchar(64) = NULL
, @p_daterange         int         = 5
, @p_startdate         varchar(8)  = NULL
, @p_enddate           varchar(8)  = NULL
, @p_user              varchar(64) = NULL
, @p_update            int         = 0         -- 0, 1 to CHange Order Dates, Destination Type
, @p_order             varchar(64) = NULL      -- '*'  to update all orders in Col1Value, Col2Value filter
, @p_orderline         varchar(64) = NULL      -- '*'  to update all orders in Col1Value, Col2Value filter
, @p_orderlinedelivery varchar(64) = NULL      -- '*'  to update all orders in Col1Value, Col2Value filter
, @p_olddate           datetime    = NULL      -- When updating more than one order
, @p_newdate           datetime    = NULL      -- New shipdate
, @p_destinationtype   varchar(64)= NULL       -- X, D, C, etc
 */
export interface GetLoadPlanningParams {
  col1value?: string;
  col2value?: string;
  warehouse?: string;
  loadid?: string;// sending load id for  Multi types
  // daterange?: number;
  startdate?: string;
  weeks?: number;
  sort?: string;
  zone?: string;
  bookingstatus?: string;
  customeraddress?: string;
  crossdock?: string;
  level?: number; //(api side does it's magic to infer this value)
  /**
   * Speficy a load that we look for orders with in a scertain distance
   */
  route?: string;
  /**
   * Specify a distance from the Load's segments that we want to search for orders
   */
  routeradius?: number;
}

// Note all params are lowercase
export interface PutLoadPlanningParams {
  orderid?: string;
  lineid?: string;
  deliveryid?: string;
  olddate?: string;
  newdate?: string;
  destination?: string;
  destinationtype?: string;
  customer?: string;
  customeraddress?: string;
  resetshipdate?: boolean;// reset all shipdates to earliest date
  warehouseid?: string;
  loadid?: string;// sending load id for  Multi types
}

export interface BaseRowDto {
  // id?: string;
  cityId?: string;
  crossdockId: string;
  zoneId: string;
  customerId: string;
  customerDeliveryId: string;
  addressDescription: string;
  destinationType: string; // D, X, etc..
  volumeTotalPast: number;
  volumeTotal1: number;
  volumeTotal2: number;
  volumeTotal3: number;
  volumeTotal4: number;
  volumeTotal5: number;
  volumeTotal6: number;
  volumeTotal7: number;
  volumeTotalFuture: number;
  orderId: string;
  orderLineId: string;
  orderLineDeliveryId: string;
  leadTime: number;
  shippingDate: Date;
  earliestDate: Date;
  latestDate: Date;
  confirmedDate: Date;
  bookingStatus: string;
  bookingId: string;
  /**
   * Might contain: load ID, cos. picklist ID, Load Number, etc
   */
  reference: string;
  loadId: string;
  itemID: string;
  quantityRequested: number;
  volumeRequested: number;
  expanded: boolean;
  lineType: string;
  level: number;
  hidden: boolean;
}

export interface Level1Row extends BaseRowDto {
  id: string;
  expanded: boolean;
  level2Rows?: Level2Row[];
}

export interface Level2Row extends BaseRowDto {
  parentId: string;
  expanded: boolean;
  level3Rows?: BaseRowDto[];
}

export interface CrossdockListItemDto extends LookupObjectDto {
  lastMileId: string;
  postalCode: string;
  country: string;
  gpsCoordinates: string;
  typeId: string; // X, D, C, etc
}

export interface LoadPlanningPutResponse {
  error: number,
  errorText: string,
}

@Injectable({
  providedIn: "root",
})
export class WarehouseService extends ListDataService {
  listName = "binmaster";
  constructor(config: ServiceConfig, dataService: DataService) {
    super(config, dataService);
    this.updateColumnDateMap();
  }

  queryErrors(query: SearchDto) {
    return this.http.get<ListResultDto<ErrorLogDto>>("warehouses/errors", {
      params: this.searchQueryToParams(query),
    });
  }

  queryDocument(query: SearchDto) {
    return this.http.get<ListResultDto<DocumentLogDto>>(
      "warehouses/documents",
      { params: this.searchQueryToParams(query) }
    );
  }

  // WAREHOUSE MASTER
  queryWarehouses(query: SearchDto) {
    return this.searchList<WarehouseMasterDto>("warehousemaster", query);
  }

  getWarehouse(code: string) {
    return this.getListItem<WarehouseMasterDto>("warehousemaster", code);
  }

  create(warehouse: WarehouseMasterDto) {
    return this.addListItem<WarehouseMasterDto>("warehousemaster", warehouse);
  }

  update(warehouse: WarehouseMasterDto) {
    return this.updateListItem<WarehouseMasterDto>(
      "warehousemaster",
      warehouse.code,
      warehouse
    );
  }

  // BIN MASTER - deprecated: we track bins via Warehouses
  queryBins(query: SearchDto) {
    return this.searchList<BinMasterDto>(this.listName, query);
  }

  getBin(code: string) {
    return this.getListItem<BinMasterDto>(this.listName, code);
  }

  /**
   * Get Warehouse Bin List with configuration for Mixed, PickFace and Dimensions
   */
  getWarehouseBins(warehouseId: string) {
    return this.http.get<BinMasterDto[]>(
      `values/list/binmaster/${warehouseId}`
    );
  }

  // Might need to use Stock api: send all warehouses+bins`
  // create/add stock warehouse
  // @todo: check api?
  addStockWarehouse(stockId: string, warehouse: StockWarehouseDto) {
    return this.http.post<StockWarehouseDto>(
      `inventory/stockmaster/${this.safeEncode(stockId)}/warehouses`,
      warehouse
    );
  }

  // not tested - probaly incorrect
  updateStockWarehouse(stockId: string, warehouse: StockWarehouseDto) {
    return this.http.put<StockWarehouseDto>(
      `inventory/stockmaster/${this.safeEncode(stockId)}/warehouses`,
      warehouse
    );
  }

  deleteStockWarehouse(stockId: string, warehouseId: string) {
    return this.http.delete(
      `inventory/stockmaster/${this.safeEncode(
        stockId
      )}/warehouses/${warehouseId}`
    );
  }

  addBin(bin: BinMasterDto) {
    return this.http.post<BinMasterDto>(
      `values/item/binmaster/${bin.warehouseId}`,
      bin
    );
  }

  updateBin(bin: BinMasterDto) {
    return this.http.put<BinMasterDto>(
      `values/item/binmaster/${bin.warehouseId}/${bin.code}`,
      bin
    );
  }

  /**
   * Useful for Balance, EOQ and Safety Level
   */
  getStockBins(warehouseId: string) {
    return this.http.get<StockBinDto[]>(`inventory/stockbins/${warehouseId}`);
  }
  // STOCK BIN WAREHOUSE
  getBinStocks(warehouseId: string, binId: string) {
    return this.http.get<StockBinDto[]>(
      `inventory/stockbins/${warehouseId}/${binId}`
    );
  }
  // array of stock bins apis
  addStockBins(stockBins: StockBinDto[]) {
    return this.http.post("inventory/stockbins", stockBins);
  }
  updateStockBins(stockBins: StockBinDto[]) {
    return this.http.put("inventory/stockbins", stockBins);
  }
  // DELETE API's?

  // single stock bin apis
  addStockBin(stockBin: StockBinDto) {
    return this.http.post("inventory/stockbin", stockBin);
  }

  updateStockBin(stockBin: StockBinDto) {
    return this.http.put("inventory/stockbin", stockBin);
  }

  // @TODO: DELETE API needs to be fixed
  deleteStockBin(stockId: string, warehouseId: string, stockBinId: string) {
    return this.http.delete(
      `inventory/stockbin/${this.safeEncode(
        stockId
      )}/${warehouseId}/${stockBinId}`
    );
  }

  // CYCLE COUNT
  searchCycleCount(query: SearchDto) {
    return this.searchList<CycleCountDto>("cyclecount", query);
  }
  // @TODO: CREATE + UPDATE

  // STOCK TAKE
  searchStockTake(query: SearchDto) {
    return this.http.get<ListResultDto<StockTakeDto>>("inventory/stocktake", {
      params: this.searchQueryToParams(query),
    });
  }
  addStockTake(stockTake: StockTakeDto) {
    return this.http.post<StockTakeDto>("inventory/stocktake", stockTake);
  }
  updateStockTake(stockTake: StockTakeDto) {
    return this.http.put<StockTakeDto>(
      `inventory/stocktake/${stockTake.stockTakeId}`,
      stockTake
    );
  }
  getStockTake(stockTakeId: string) {
    return this.http.get<StockTakeDto>(`inventory/stocktake/${stockTakeId}`);
  }
  // inventory/stocktake/{stockTakeId}/{count}/{binId}/{stockid}
  deleteStockTakeBinCount(
    stockTakeId: string,
    count: number,
    binId: string,
    stockId: string
  ) {
    return this.http.delete(
      `inventory/stocktake/${stockTakeId}/${count}/${this.safeEncode(
        binId
      )}/${this.safeEncode(stockId)}`
    );
  }

  // STOCK TAKE BIN
  updateStockTakeBin(stockTakeId: string, stockTakeBin: StockTakeBinDto) {
    return this.http.put<StockTakeBinDto>(
      `inventory/stocktake/${stockTakeId}/count`,
      stockTakeBin
    );
  }

  // Planning/Distribution
  getLoadPlanningOverview<T>(params: GetLoadPlanningParams) {
    const httpParms = this.objectToParams(params);
    console.log("*** getPlanner ***");
    console.dir(httpParms.keys().reduce((pv, cv) => ({...pv, [cv]: httpParms.get(cv)}), {}));
    return this.http.get<T[]>("distribution/loadplanning", {
      params: httpParms,
    });
  }

  putLoadPlanningChanges(params: PutLoadPlanningParams) {
    const httpParms = this.objectToParams(params);
    console.log("*** putLoadPlanningChanges ***");
    // console.dir(this.objectToParams(params).keys().map(k => `${k}: ${this.objectToParams(params).get(k)}`));
    console.dir(httpParms.keys().reduce((pv, cv) => ({...pv, [cv]: httpParms.get(cv)}), {}));
    return this.http.put<LoadPlanningPutResponse>(`distribution/loadplanning`, null, {
      params: httpParms,
    });
  }

  // BOOKINGS
  searchBookings(query: SearchDto) {
    return this.http.get<ListResultDto<DeliveryBookingDto>>(
      "distribution/bookings",
      { params: this.searchQueryToParams(query) }
    );
  }
  getBooking(bookingId: string) {
    return this.http.get<DeliveryBookingDto>(
      `distribution/booking/${bookingId}`
    );
  }
  addBooking(booking: DeliveryBookingDto) {
    return this.http.post<DeliveryBookingDto>(`distribution/booking`, booking);
  }
  updateBooking(booking: DeliveryBookingDto) {
    return this.http.put<DeliveryBookingDto>(
      `distribution/booking/${booking.bookingId}`,
      booking
    );
  }

  // LOADS
  searchLoads(query: SearchDto) {
    return this.http.get<ListResultDto<LoadDto>>("distribution/loads", {
      params: this.searchQueryToParams(query),
    });
  }
  getLoad(loadId: string, withManifest = false, print = false) {
    return this.http.get<LoadDto>(`distribution/load/${loadId}${withManifest ? "/manifest" : ""}${print ? "/print" : ""}`);
  }
  addLoad(load: LoadDto) {
    return this.http.post<LoadDto>(`distribution/load`, load);
  }
  updateLoad(load: LoadDto) {
    return this.http.put<LoadDto>(`distribution/load/${load.loadId}`, load);
  }
  changeLoadDate(loadId: string, date: Date) {
    return this.http.put<LoadDto>(
      `distribution/load/${loadId}/plandate/${date.toISOString()}`,
      null
    );
  }

  columnDateMap: { [key: string]: Date } = {};
  updateColumnDateMap(baseDate: Date = Date.today()) {
    this.columnDateMap = {
      volumeTotalPast: baseDate.add(-1, "day"),
      volumeTotal1: baseDate,
      volumeTotal2: baseDate.add(1, "day"),
      volumeTotal3: baseDate.add(2, "day"),
      volumeTotal4: baseDate.add(3, "day"),
      volumeTotal5: baseDate.add(4, "day"),
      volumeTotal6: baseDate.add(5, "day"),
      volumeTotal7: baseDate.add(6, "day"),
      volumeTotalFuture: baseDate.add(7, "day"),
    };
  }

  findColumnByDate(givenDate: Date) {
    if (
      givenDate.stripTime() <= this.columnDateMap["volumeTotalPast"].stripTime()
    ) {
      return "volumeTotalPast";
    } else if (
      givenDate.stripTime() <
      this.columnDateMap["volumeTotalFuture"].stripTime()
    ) {
      // check inside column map which day it is
      return Object.keys(this.columnDateMap)
        .filter((key) => sameDate(this.columnDateMap[key], givenDate))
        .reduce((acc, key) => key, "");
    } else {
      return "volumeTotalFuture";
    }
  }

  getCrossdocks() {
    return this.getList<CrossdockListItemDto>("crossdockmaster");
  }

  queryLoads(
    term: string,
    extras: { crossdockId?: string; customerId?: string; planDate?: string, destinationType?: string } = { crossdockId: "X" }
  ) {
    const search = newSearchDto();
    search.filters = { loadId: term, ...extras };
    return this.searchLoads(search);
  }

  getLoadConfigurations(term: string, carrierId: string) {
    return this.queryList<LookupObjectDto>(
      "loadconfiguration",
      carrierId,
      term
    );
  }

  /**
   * [HttpPost("load/{loadId}/order/{orderId}/{lineId}")]
    [HttpPost("load/{loadId}/{segmentId}/order/{orderId}/{lineId}")]
    [HttpPut("load/{loadId}/{segmentId}/order/{orderId}/{lineId}")]
    [HttpDelete("load/{loadId}/order/{orderId}/{lineId}")]
   */
  addOrderToLoad(loadId: string, orderId: string, lineId?: string, segmentId?: string) {
    if (segmentId) {
      return this.http.put(`distribution/load/${loadId}/${segmentId}/order/${orderId}${lineId ? `/${lineId}`: ''}`, null);
    }
    return this.http.put(`distribution/load/${loadId}/order/${orderId}${lineId ? `/${lineId}`: ''}`, null);
  }

  /**
   * Link Orders to Load
   * Pass a dictionary :
      {
      "{orderid1}":[]
      "{order2id}:[{lineid}]
      }
      blank array for whole order
      else line ids on order to assign to load
   */
  addOrdersToLoad(loadId: string, orderMap: Record<string, string[]>) {
    return this.http.put(`distribution/load/${loadId}/order`, orderMap);
  }

  /**
   * [HttpPost("load/{loadId}/order/delete")]
   */
  deleteOrdersFromLoad(loadId: string, orderMap: Record<string, string[]>) {
    return this.http.post(`distribution/load/${loadId}/order/delete`, orderMap);
  }

  updateOrderInLoad(loadId: string, orderId: string, lineId: string, segmentId: string) {
    return this.http.put(`distribution/load/${loadId}/${segmentId}/order/${orderId}/${lineId}`, null);
  }

  /**
   * DELETE "load/{loadId}/order/{orderId}/{lineId}"
    OR
    DELETE "load/{loadId}/order/{orderId}"
    OR
    DELETE "load/{loadId}/order"
   */
  deleteOrderFromLoad(loadId: string, orderId: string, lineId: string) {
    return this.http.delete(`distribution/load/${loadId}/order/${orderId}/${lineId}`);
  }

  mapOrderControls(orderIdKey: string = 'orderId', lineIdKey: string = 'orderLineId') {
    return (pv, cv) => {
      // map order lines on orders to load
      const orderId = cv.get(orderIdKey).value;
      const orderLineId = cv.get(lineIdKey).value;
      return {...pv, [orderId]: [...(pv[orderId] || []), orderLineId]};
    }
  }

}
